import React, { FC, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
} from "@mui/material";
import { PayPalPayment } from "./PayPalPayment";
import { useFirebaseDatum } from "../shared/UseFirebase";
import { KeyMap } from "crewtimer-common";
import { InvoiceItem } from "./PaymentTypes";

interface Address {
  name: string;
  email: string;
  street: string;
  city: string;
  state: string;
  zip: string;
}

interface InvoiceProps {
  /** Optional logo URL for the vendor header */
  logoUrl?: string;
  /** Vendor / Company details */
  vendor: Address;
  /** Purchaser / Client details */
  purchaser: Address;
  /** Invoice date displayed in the header */
  invoiceDate: string;
  /** Invoice number displayed in the header */
  invoiceNumber: string;
  /** Event unique secret key */
  cloudKey: string;
  /** Single invoice item */
  items: InvoiceItem[];
}

export const Invoice: FC<InvoiceProps> = ({
  logoUrl,
  vendor,
  purchaser,
  invoiceDate,
  invoiceNumber,
  cloudKey,
  items,
}) => {
  const total = items.reduce(
    (sum, item) =>
      sum +
      (item.selected && item.feeType === "Fee"
        ? item.quantity * item.priceEach
        : 0),
    0
  );
  const [approval, invoiceInitialized] = useFirebaseDatum(
    `invoices/${cloudKey}/approved`
  );
  const [approvalResult, setApprovalResult] = useState<KeyMap>({});
  const receipt = approval || approvalResult;

  if (!invoiceInitialized) {
    return <CircularProgress />;
  }

  const onApprove = (data, actions) => {
    return actions.order.capture().then((details) => {
      setApprovalResult({ resource: details });
    });
  };

  return (
    <Box sx={{ maxWidth: 600, margin: "0 auto", p: 2 }}>
      {/* Header */}
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={4}>
          {logoUrl && (
            <Box>
              <img
                src={logoUrl}
                alt="CrewTimer"
                style={{ maxWidth: "100px", height: "auto" }}
              />
            </Box>
          )}
        </Grid>
        <Grid item xs={8} textAlign="right">
          <Typography variant="h6" fontWeight="bold">
            {vendor.name}
          </Typography>
          {vendor.email && (
            <Typography variant="body2">{vendor.email}</Typography>
          )}
          {vendor.street && (
            <Typography variant="body2">{vendor.street}</Typography>
          )}
          {vendor.state && (
            <Typography variant="body2">
              {vendor.city}, {vendor.state} {vendor.zip}
            </Typography>
          )}
        </Grid>
      </Grid>

      {/* Invoice Info */}
      <Box sx={{ mt: 2 }}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="body1" fontWeight="bold">
              {receipt ? "Receipt:" : "Invoice:"} {invoiceNumber}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">Date: {invoiceDate}</Typography>
          </Grid>
        </Grid>
      </Box>

      {/* <Box sx={{ mt: 2 }}>
        <Grid item>
          <Typography variant="body1" fontWeight="bold">
            Title: {invoiceProject}
          </Typography>
        </Grid>
      </Box> */}

      {/* Purchaser Address */}
      <Box sx={{ mt: 4 }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              Bill To:
            </Typography>
            <Typography variant="body2">{purchaser.name}</Typography>
            {purchaser.email && (
              <Typography variant="body2">{purchaser.email}</Typography>
            )}
            {purchaser.street && (
              <Typography variant="body2">{purchaser.street}</Typography>
            )}
            {purchaser.state && (
              <Typography variant="body2">
                {purchaser.city}, {purchaser.state} {purchaser.zip}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Box>

      {/* Invoice Item Table */}
      <TableContainer component={Paper} sx={{ mt: 4 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>MobileId</TableCell>
              <TableCell>Description</TableCell>
              <TableCell align="right">Entries</TableCell>
              <TableCell align="right">Price</TableCell>
              <TableCell align="right">Total USD</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item) => (
              <TableRow key={item.name}>
                <TableCell>{item.name}</TableCell>
                <TableCell>
                  <>{item.description}</>
                </TableCell>
                <TableCell align="right">{item.quantity}</TableCell>
                <TableCell align="right">
                  ${item.priceEach.toFixed(2)}
                </TableCell>
                <TableCell align="right">
                  {total ? `${total.toFixed(2)}` : "Free"}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* Payment Section */}
      {total > 0 && (
        <Box
          sx={{
            width: "80%",
            margin: "2rem auto 0",
            p: 2,
            textAlign: "center",
            border: "1px solid #ccc",
            borderRadius: 1,
          }}
        >
          {receipt.Amount ? (
            <>
              <Typography variant="h6" gutterBottom>
                Payment Complete
              </Typography>
              <Typography variant="body1">
                <strong>{`Total Paid: ${receipt.Amount} USD`}</strong>
              </Typography>
              <Typography variant="body2" sx={{ mt: 1 }}>
                {receipt.Method}
              </Typography>
              <Typography variant="body2" sx={{ mt: 1 }}>
                Thanks for your support!
              </Typography>
            </>
          ) : receipt?.resource ? (
            <>
              <Typography variant="h6" gutterBottom>
                Payment Complete
              </Typography>
              <Typography variant="body1">
                <strong>
                  {`Total Paid: ${receipt.resource.purchase_units[0].amount.value} ${receipt.resource.purchase_units[0].amount.currency_code}`}
                </strong>
              </Typography>
              <Typography variant="body2" sx={{ mt: 1 }}>
                {new Date(receipt.resource.update_time).toLocaleString()}
              </Typography>{" "}
              <Typography variant="body2" sx={{ mt: 1 }}>
                {`${receipt.resource.payer.name.surname}, ${receipt.resource.payer.name.given_name}`}
              </Typography>
              <Typography variant="body2" sx={{ mt: 1 }}>
                {receipt.resource.payer.email_address}
              </Typography>
              <Typography variant="body2" sx={{ mt: 1 }}>
                Thanks for your support!
              </Typography>
            </>
          ) : (
            <>
              <Typography variant="h6" gutterBottom>
                Payment
              </Typography>
              <Typography variant="body1">
                <strong>Total Due:</strong> ${total ? total.toFixed(2) : "Free"}
              </Typography>
              <Box sx={{ height: "2em" }} />
              {total > 0 && (
                <Box className="noprint">
                  <PayPalPayment
                    cloudKey={cloudKey}
                    items={items}
                    onApprove={onApprove}
                  />
                </Box>
              )}
              <Typography variant="body2" sx={{ mt: 1 }}>
                Make checks payable to <em>{vendor.name}</em>.<br />
                Venmo to <em>@crewtimer</em>
                <br />
                Paypal to <em>glenne@crewtimer.com</em>
                <br />
                Pay via{" "}
                <a href="https://admin.crewtimer.com/store">CrewTimer Store</a>
              </Typography>
            </>
          )}
        </Box>
      )}
    </Box>
  );
};
